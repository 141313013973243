.GamerView .MuiTableCell-alignRight[value=""]   {
  background-color: #FFC107;
}

.GamerView .withoutRelicat .MuiTableRow-root[index="3"]   {
  background-color: #009688;
  color: '#000000',
}

.GamerView .withRelicat .MuiTableRow-root[index="4"]   {
  background-color: #009688;
  color: '#000000',
}

.GamerView .withRelicat .MuiTableRow-root[index="6"] {
  color: red !important;
  font-style: bold
}
.GamerView .withoutRelicat .MuiTableRow-root[index="5"] {
  color: red !important;
  font-style: bold
}

.GamerView .withRelicat .MuiTableRow-root[index="6"][value="0"] {
  color: black !important;
  font-style: bold
}

.GamerView .withoutRelicat .MuiTableRow-root[index="5"][value="0"] {
  color: black !important;
  font-style: bold
}


.GamerView .timer-wrapper {
  margin: 1em;
  float: right;
  width: 10%;
}

.GamerView .title {
  margin-right: 10%;
}

.GamerView .rolesliste {
  margin-right: 10%;
  width: max-content;
  margin: auto;
  margin-top: 5%;
  font-size: large;
}

.GamerView .gametable {
  clear: both;
  padding: 10px;
}

.GamerView .MuiTableCell-root[value="0"] {
  color: black !important ;
}

.GamerView .MuiTableCell-head {
  background-color: #7986cb;
  font-style: bold;
  color: white !important ;
}

timer-wrapper {
  display: flex;
  justify-content: center;
}

.buttonAide {
  position: left;
  display: block;
  float: left;
  width: 7em;
  left: 0;
  background-color: lightskyblue;
  font: bold;
  font-size: 1em;
}

.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s;
}

